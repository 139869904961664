// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pesquisar-js": () => import("./../../../src/pages/pesquisar.js" /* webpackChunkName: "component---src-pages-pesquisar-js" */),
  "component---src-templates-alimentos-pages-js": () => import("./../../../src/templates/alimentos_pages.js" /* webpackChunkName: "component---src-templates-alimentos-pages-js" */),
  "component---src-templates-calcular-macronutrientes-js": () => import("./../../../src/templates/calcular-macronutrientes.js" /* webpackChunkName: "component---src-templates-calcular-macronutrientes-js" */),
  "component---src-templates-calcular-taxa-metabolica-basal-js": () => import("./../../../src/templates/calcular-taxa-metabolica-basal.js" /* webpackChunkName: "component---src-templates-calcular-taxa-metabolica-basal-js" */),
  "component---src-templates-calculo-nutricional-js": () => import("./../../../src/templates/calculo-nutricional.js" /* webpackChunkName: "component---src-templates-calculo-nutricional-js" */),
  "component---src-templates-links-js": () => import("./../../../src/templates/links.js" /* webpackChunkName: "component---src-templates-links-js" */),
  "component---src-templates-macros-pages-js": () => import("./../../../src/templates/macros_pages.js" /* webpackChunkName: "component---src-templates-macros-pages-js" */),
  "component---src-templates-nutrientes-pages-js": () => import("./../../../src/templates/nutrientes_pages.js" /* webpackChunkName: "component---src-templates-nutrientes-pages-js" */)
}

